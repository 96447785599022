.err-con[data-v-205a01fe] {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.err-con .err404[data-v-205a01fe] {
    width: 764px;
    height: 481px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.err-con .err404 .go-home[data-v-205a01fe] {
      width: 130px;
      height: 38px;
      font-size: 16px;
      line-height: 38px;
      color: #fff;
      background: #00539f;
      text-align: center;
      display: inline-block;
      cursor: pointer;
}
